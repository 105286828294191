<template>
  <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
    <div>
      <v-card-title class="p-12">
        <img src="@/assets/images/skyfi-logo.png" width="110px" />
      </v-card-title>
      <v-divider></v-divider>
      <v-list shaped>
        <v-list-item-group>
          <router-link v-for="(item, index) in menuItems" :key="index" :to="item.link"
            exact-active-class="nav-item-active">
            <v-list-item :class="{ 'v-item--active v-list-item--active': item.link === $route.path }">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </router-link>
          <!-- <router-link v-if="isAdmin" to='/skyfi-dashboard/network-files'
            exact-active-class="nav-item-active">
            <v-list-item :class="{ 'v-item--active v-list-item--active': '/skyfi-dashboard/network-files' === $route.path }">
              <v-list-item-icon>
                <v-icon>mdi-web</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Network Files</v-list-item-title>
            </v-list-item>
          </router-link> -->
          <div v-if="staffMenu">
            <v-list-group prepend-icon="mdi-account-check-outline" :value="false">
              <template v-slot:activator>
                <v-list-item-title>B.O. Staff</v-list-item-title>
              </template>
              <router-link v-for="([title, icon, link], i) in staffMenu" :key="i" :to="link"
                exact-active-class="v-list-item--active">
                <v-list-item :class="{ 'v-item--active v-list-item--active': link === $route.path }">
                  <v-list-item-title v-text="title"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </router-link>
            </v-list-group>
          </div>

        </v-list-item-group>
      </v-list>
    </div>
    <v-btn color="error" class="ma-4" outlined @click.prevent="logout">
      Logout
    </v-btn>
  </div>
</template>

<script>
import allMenus from '../utils/menus';
export default {
  data() {
    var environment = '/'
    const serverType = localStorage.getItem('serverType')
    if (serverType == 'staging') {
      environment = '/staging/'
    } else if (serverType == 'livestaging') {
      environment = '/live-staging/'
    }
    return {
      staffMenu: localStorage.getItem('menus') === 'ALL'
        ? [
          ['SkyFi Staff', 'mdi-account-box-outline', `/skyfi-dashboard${environment}staffs`],
          ['Create B.O. Staff', 'mdi-account-plus-outline', `/skyfi-dashboard${environment}create-staff`],
          ['Create Role', 'mdi-badge-account-outline', `/skyfi-dashboard${environment}create-role`],
          ['Roles', 'mdi-badge-account-outline', `/skyfi-dashboard${environment}roles`],
          // ['Sameday Info', 'mdi-information-outline', '/skyfi-dashboard/sameday-info'],
          // ['B.O. Staff Permission', 'mdi-account-lock-outline', '/skyfi-dashboard/permission'],
        ]
        : false,
      menuItems: [
        // {
        //   title: 'Business',
        //   icon: 'mdi-license',
        //   link: '/skyfi-dashboard/business',
        // },
        // {
        //   title: 'Accounts',
        //   icon: 'mdi-account-key-outline',
        //   link: '/skyfi-dashboard/accounts',
        // },
        // {
        //   title: 'Transaction List',
        //   icon: 'mdi-cash-multiple',
        //   link: '/skyfi-dashboard/transactions',
        // },
        // {
        //   title: 'Users',
        //   icon: 'mdi-account-group-outline',
        //   link: '/skyfi-dashboard/user-list',
        // },
        // {
        //   title: 'Cards',
        //   icon: 'mdi-cards-outline',
        //   link: '/skyfi-dashboard/cards',
        // },
        // {
        //   title: 'Contacts',
        //   icon: 'mdi-archive-outline',
        //   link: '/skyfi-dashboard/contacts',
        // },
      ],
      isAdmin: localStorage.getItem('menus') === 'ALL' ? true : false,
   
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('menus');
      localStorage.removeItem('permissions');
      this.$router.push('/admin-login');
      location.reload()
    }
  },
  
  mounted() {
    var environment = '/'
    const serverType = localStorage.getItem('serverType')
    if (serverType == 'staging') {
      environment = '/staging/'
    } else if (serverType == 'livestaging') {
      environment = '/live-staging/'
    }else if (serverType == 'local') {
      environment = '/local/'
    }
    if (!localStorage.getItem('menus')) {
      this.$router.push('/admin-login')
    } else {
      if (localStorage.getItem('menus') === 'ALL') {
        const mainRoutes = allMenus(environment).map((item) => {
          if (item.type === 'main') {
            return {
              title: item.text,
              icon: item.icon,
              link: item.path
            }
          } return false
        })
        this.menuItems = mainRoutes
      } else {
        const menu_ids = JSON.parse(localStorage.getItem('menus'))
        const filteredMenu = allMenus(environment).filter(obj1 => {
          return menu_ids.some(obj2 => obj2 == obj1.id);
        });
        const mainRoutes = filteredMenu.map((item) => {
          if (item.type === 'main') {
            return {
              title: item.text,
              icon: item.icon,
              link: item.path,
            }
          } return false
        })
        this.menuItems = mainRoutes
      }
    }
  }
};
</script>
<style lang="scss">
.v-list-item-group {
  >* {
    margin: 10px 0px;

    >.v-list-item {
      margin: 10px 0px;
    }
  }
}
</style>